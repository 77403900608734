import {GetCurrentLoginInformationsOutput, layoutStore_NS} from '@/shared';
import {IAbp} from '@/shared/abp/interfaces';
import {abpStore_NS, rootStore} from '@/shared/store';

class AbpService {
    set(val: any) {
        rootStore.commit(`${abpStore_NS}/set`, val);
    }

    get abp(): IAbp {
        const storeAbp = rootStore.getters[`${abpStore_NS}/get`];
        if (storeAbp.localization) {
            return storeAbp;
        }
        return abp as any;
    }

    get faviconIcon():string{
        return rootStore.getters[`${abpStore_NS}/faviconIcon`];
    }

    setFaviconIcon(val:any){
        rootStore.commit(`${abpStore_NS}/setFaviconIcon`, val);
    }

    setModuleKey(val: any) {
        rootStore.commit(`${abpStore_NS}/setModuleKey`, val);
    }

    get moduleKey(): string {
        return rootStore.getters[`${abpStore_NS}/moduleKey`];
    }

    setLoginInfo(val: GetCurrentLoginInformationsOutput) {
        rootStore.commit(`${abpStore_NS}/setLoginInfo`, val);
    }

    get loginInfo(): GetCurrentLoginInformationsOutput {
        return rootStore.getters[`${abpStore_NS}/getLoginInfo`];
    }

    get tabincludes(): Array<string> {
        return rootStore.getters[`${abpStore_NS}/tabincludes`];
    }

    addInclude(include: string) {
        rootStore.commit(`${abpStore_NS}/addInclude`, include);
    }

    removeInclude(include: string) {
        rootStore.commit(`${abpStore_NS}/removeInclude`, include);
    }

    clearInclude() {
        rootStore.commit(`${abpStore_NS}/clearInclude`);
    }

    /**
     * 获取当前语言
     */
    get currentLang(): string {
        return rootStore.getters[`${layoutStore_NS}/lang`];
    }

    setCurrentLang(val: string = 'cn') {
        if (val == 'zh-Hans') {
            val = 'cn';
            //中文与工作流约定cn，英文为en
        }
        rootStore.commit(`${layoutStore_NS}/setLang`, val);
    }
}

const abpService = new AbpService();
export default abpService;
