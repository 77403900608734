import {GetCurrentLoginInformationsOutput} from '@/shared/service-proxies';

const now = new Date();
now.setDate(now.getDate() + 365);

/**
 * state
 */
const state = {
    abp: {},
    moduleKey: '',
    loginInfo: undefined,
    tabincludes: [],
    faviconIcon: ""
};

/**
 * getters
 */
const getters = {
    get: (state): any => {
        return state.abp;
    },
    moduleKey: (state) => {
        if (state.moduleKey) {
            return state.moduleKey;
        } else {
            return abp.utils.getCookieValue('modeule-key');
        }
    },
    faviconIcon: (state) => {
        if (state.faviconIcon) {
            return state.faviconIcon;
        } else {
            return abp.utils.getCookieValue('faviconIcon');
        }
    },
    getLoginInfo: (state): any => {
        return state.loginInfo;
    },
    tabincludes: (state) => {
        return state.tabincludes;
    },
};

/**
 * mutations
 */
const mutations = {
    set: (state, val) => {
        state.abp = val;
    },
    setFaviconIcon: (state, val) => {
        state.faviconIcon = val;
        abp.utils.setCookieValue('faviconIcon', val, now, '/');
    },
    setModuleKey: (state, val) => {
        state.moduleKey = val;
        abp.utils.setCookieValue('modeule-key', val, now, '/');
    },
    setLoginInfo: (state, val: GetCurrentLoginInformationsOutput) => {
        state.loginInfo = val;
    },
    addInclude: (state, include) => {
        if (state.tabincludes.findIndex((i) => i == include) == -1) {
            state.tabincludes.push(include);
        }
    },
    removeInclude: (state, include) => {
        const index = state.tabincludes.findIndex((i) => i == include);
        if (index != -1) {
            state.tabincludes.splice(index, 1);
        }
    },
    clearInclude: (state) => {
        state.tabincludes = [];
    },
};

/**
 * actions
 */
const actions = {};

/**
 * 将 abp 对象交给 vuex 监控
 * @type
 */
const AbpStore = {
    namespaced: true,
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
};

export default AbpStore;
