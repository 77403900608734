import { Menu } from "@/shared/common";

/**
 * 全局的左侧边栏的菜单导航配置信息
 */
export class AppMenus {
  /**
   * 头部菜单
   */
  static HeaderMenus: Menu[] = [];
  /**
   * 工作台
   */
  static DashboardMenus: Menu[] = [
    {
      // 工作台
      text: "",
      i18n: "Dashboard",
      acl: "工作台",
      icon: "anticon anticon-dashboard",
      link: "/app/main/dashboard",
      title: "工作台",
    },
  ];
  /**
   * 宿主特有菜单
   */
  static HostMenus: Menu[] = [
    // {
    //     // 工作台
    //     text: '',
    //     i18n: 'Dashboard',
    //     acl: '工作台',
    //     icon: 'anticon anticon-dashboard',
    //     link: '/app/main/dashboard',
    //     value: "host-1"
    // },
    {
      // 系统功能&&菜单
      text: "",
      i18n: "HostModule",
      acl: undefined,
      icon: "anticon anticon-dashboard",
      link: "/app/admin/host-module",
      value: "host-2",
    },
    {
      // 意见反馈清单
      text: "",
      i18n: "意见反馈清单",
      acl: undefined,
      //是否共享
      public: true,
      icon: "anticon anticon-dashboard",
      link: "/app/admin/host-feedback",
      value: "host-6",
    },
    {
      // 意见反馈清单
      text: "",
      i18n: "意见反馈报表统计",
      //是否共享
      public: true,
      acl: undefined,
      icon: "anticon anticon-dashboard",
      link: "/app/admin/report",
      value: "host-7",
    },
    {
      // 接口管理
      text: "",
      i18n: "接口管理",
      public: true,
      acl: undefined,
      icon: "anticon anticon-dashboard",
      link: "/app/admin/interface-manager",
      value: "host-8",
    },
    {
      // 租户
      text: "",
      i18n: "Tenants",
      acl: undefined,
      icon: "anticon anticon-dashboard",
      link: "/app/admin/tenants",
      value: "host-3",
    },
    {
      // 租户
      text: "",
      i18n: "App更新管理",
      acl: undefined,
      icon: "anticon anticon-dashboard",
      link: "/app/admin/app-update-manage",
      value: "host-3-1",
    },
    {
      // 宿主机器设置/维护
      text: "",
      i18n: "Maintenance",
      acl: "",
      icon: "anticon anticon-setting",
      link: "/app/admin/maintenance",
      value: "host-4",
    },
    {
      // 宿主设置
      text: "",
      i18n: "Settings",
      acl: undefined,
      icon: "anticon anticon-setting",
      link: "/app/admin/host-settings",
      value: "host-5",
    },
    {
      // 多语言
      text: "",
      i18n: "多语言",
      acl: '',
      public: true,
      icon: "anticon anticon-setting",
      link: "/app/admin/multilingual-setting",
      value: "host-10",
    },
    {
      // APP系统&菜单管理
      text: "",
      i18n: "APP系统&菜单管理",
      acl: undefined,
      public: true,
      icon: "anticon anticon-setting",
      link: "/app/admin/master-moudlezuhu",
      value: "host-11",
    },
  ];
}
