import AppCompoentBase from './app-component-base';

const ModalComponentBase = {
	mixins: [AppCompoentBase],
	props: ['modelRef', 'modelData'],
	async mounted() {
		// if (this.$refs.moveModal) {
		// 	console.dir(this.$refs.moveModal);
		// 	const modal = await this.getElementByClassName('ant-modal-content', this.$refs.moveModal);
		// 	this.move(this.$refs.moveModal, modal);
		// }
	},
	// beforeDestroy() {
	// 	this.$refs.moveModal.onmousedown = null;
	// 	document.onmousemove = null;
	// 	document.onmouseup = null;
	// },
	methods: {
		success(result) {
			if (typeof result !== 'boolean') {
				this.modelRef.close(result);
				return;
			}
			this.modelRef.close(true);
		},
		close() {
			this.modelRef.close(false);
		},
		fullData() {
			if (this.modelData) {
				for (let key in this.modelData) {
					this[key] = this.modelData[key];
				}
			}
		},
		move(elClick, elMove) {
			function getStyle(dom, attr) {
				return getComputedStyle(dom)[attr];
			}
			elClick.onmousedown = (e) => {
				if (!e) return;
				// 鼠标按下，计算当前元素距离可视区的距离
				const disX = e.clientX;
				const disY = e.clientY;
				const screenWidth = document.body.clientWidth; // body当前宽度
				const screenHeight = document.documentElement.clientHeight; // 可见区域高度(应为body高度，可某些环境下无法获取)

				const dragDomWidth = elMove.offsetWidth; // 对话框宽度
				const dragDomheight = elMove.offsetHeight; // 对话框高度

				const minDragDomLeft = elMove.offsetLeft;

				const maxDragDomLeft = screenWidth - elMove.offsetLeft - dragDomWidth;
				const minDragDomTop = elMove.offsetTop;
				const maxDragDomTop = screenHeight - elMove.offsetTop - dragDomheight;
				// 获取到的值带px 正则匹配替换
				const domLeft = getStyle(elMove, 'left');
				const domTop = getStyle(elMove, 'top');
				let styL = +domLeft;
				let styT = +domTop;

				// 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
				if (domLeft.includes('%')) {
					styL = +document.body.clientWidth * (+domLeft.replace(/%/g, '') / 100);
					styT = +document.body.clientHeight * (+domTop.replace(/%/g, '') / 100);
				} else {
					styL = +domLeft.replace(/px/g, '');
					styT = +domTop.replace(/px/g, '');
				}

				document.onmousemove = function(e) {
					// 通过事件委托，计算移动的距离
					let left = e.clientX - disX;
					let top = e.clientY - disY;

					// 边界处理
					if (-left > minDragDomLeft) {
						left = -minDragDomLeft;
					} else if (left > maxDragDomLeft) {
						left = maxDragDomLeft;
					}

					if (-top > minDragDomTop) {
						top = -minDragDomTop;
					} else if (top > maxDragDomTop) {
						top = maxDragDomTop;
					}
					// 移动当前元素
					elMove.style.cssText += `;left:${left + styL}px;top:${top + styT}px;`;
				};

				document.onmouseup = () => {
					document.onmousemove = null;
					document.onmouseup = null;
				};
			};
		},
		getElementByClassName(className, target) {
			return new Promise(async (resolve, reject) => {
				if (!target || !className) {
					reject('必填参数为空');
				}
				let element;
				let parent = target.parentNode;
				while (true) {
					if (parent.className == className) {
						element = parent;
						break;
					} else {
						element = await this.getElementByClassName(className, parent);
						if (element) {
							break;
						}
					}
				}
				resolve(element);
			});
		},
	},
};

export default ModalComponentBase;
