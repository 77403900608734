import {ILayoutState} from './interfaces';

/**
 * state
 */
const state: ILayoutState = {
	value: {
		collapsed: false,
		theme: 'dark',
		reuseTab: true,
		isPad: false,

	},
	lang: 'cn'
};

/**
 * mutations
 */
const mutations = {
	set: (state, val) => {
		state.value = val;
	},
	setLang: (state, val) => {
		if (!val) {
			state.lang = 'cn';
		} else {
			state.lang = val;
		}
	}
};

/**
 * getters
 */
const getters = {
	get: (state: ILayoutState) => {
		return state.value;
	},
	lang: (state) => {
		return state.lang;
	}
};

/**
 * 将 layout 对象交给 vuex 监控
 * @type
 */
const LayoutStore = {
	namespaced: true,
	state: state,
	getters: getters,
	actions: {},
	mutations: mutations,
};

export default LayoutStore;
