import { AppConsts } from '@/abpPro/AppConsts';
import { abpService } from '@/shared/abp';

class AppAuthService {
    logout(reload?: boolean): void {
        abpService.abp.auth.clearToken();
        abpService.abp.utils.deleteCookie('SSOGuid', abp.appPath);
        abpService.abp.utils.deleteCookie('modeule-key', abp.appPath);
        abpService.abp.utils.deleteCookie('faviconIcon', abp.appPath);
        abpService.clearInclude();
        if (reload !== false) {
            location.href = AppConsts.appBaseUrl;
        }
    }
}

const appAuthService = new AppAuthService();
export default appAuthService;
